.App {
  background: url("./assets/bgimage.jpg");
  background-size: cover;
  height: 100vh;
  border: 5px solid #fff;
  box-sizing: border-box;
}

.particles {
  height: 100%;
  width: 100%;
  position: fixed;
}

.image-wrapper {
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}